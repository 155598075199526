import { useLocation } from "react-router-dom";
import * as React from "react";
import "dayjs/locale/en-in";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { getLocation, getProductClass } from "./events_master";
import { traceEvents } from "./api";
import TraceV2 from "./components/TraceV2";
import TraceV1 from "./components/TraceV1";
import { Box, Typography, Stack } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./loaderStyles.css"; 
import { OrgInfo, TraceEvent, TracesItem, TraceItemV2, TraceEventsResponse } from "./types";
import { getCertificatesForOrgInfo, getCertificatesForProductOrLocation } from "./utils";

dayjs.extend(localizedFormat);
dayjs.locale("en-in");

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

// The map of potential endpoints required to be invoked
// Key will be composed based on the env parameter in the URL
const endpointKey = {
  "ardra-0": "https://r1b1esqqa5.execute-api.ap-south-1.amazonaws.com",
  "ardra-1": "https://mtbjlz77ff.execute-api.ap-south-1.amazonaws.com",
  "taas-dev": "https://mtbjlz77ff.execute-api.ap-south-1.amazonaws.com",
  "taas-staging": "https://b57yoc3peh.execute-api.ap-south-1.amazonaws.com",
  "taas-prod": "https://vajo2y8mnk.execute-api.ap-south-1.amazonaws.com",
};

export default function Trace() {
  let query = useQuery();
  const trace_item = query.get("trace_item") || undefined;
  // const createdEpoch = query.get("eventEndEpoch") || undefined;
  const createdEpoch = undefined;
  const trace_item_id = query.get("trace_item_id") || undefined;
  const org_id = query.get("org_id") || undefined;
  
  /*
    Env helps us determine which endpoint to invoke to fetch the trace
    For ardra organics, env is 0
    For seafood products, env is 1
    For Events V2 release, env may be:
      - dev for dev environment
      - stg for staging environment
      - will not exist 
  */
  const env = query.get("env");

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [traces, setTraces] = React.useState<TraceItemV2[]>([]);
  const [orgInfo, setOrgInfo] = React.useState<OrgInfo | null>(null);

  React.useEffect(() => {
    const getTraces = async () => {
      if ((!trace_item && !trace_item_id) || !org_id) {
        window.location.href = "https://tralexho.com/";
        return;
      }

      try {
        let base_url: string;
        let base_key: string;
        let results: TraceEventsResponse = [];
        let tmpResults: any[] = [];

        // ardra flow
        if (trace_item) {
          base_key = "ardra";
          base_url = endpointKey[`${base_key}-${env}`];
          results = await traceEvents({ baseUrl: base_url, traceItem: trace_item, orgId: "adra-organics" });

          results.forEach((res: any) => {
            const productClass = getProductClass(res.output_product_instances.split("-")[0]);
            const eventTime = dayjs(res.event_start_unix_epoch * 1000);
            const item: TracesItem = {
              userEvent: res.user_event,
              eventDay: eventTime.format("L"),
              eventTime: eventTime.format("h:mm A"),
              location: getLocation(res.location).toLowerCase(),
              productClass: productClass.toLowerCase(),
              thumbnail: res.image_thumbnail_presigned_url,
            };
            tmpResults.push(item);
          });

          setTraces(tmpResults);
        } else {
          base_key = "taas";
          base_url = endpointKey[`${base_key}-${env || "prod"}`];
          results = await traceEvents({ baseUrl: base_url, traceItem: trace_item_id!, orgId: org_id, createdEpoch });

          const { trace_events_list: traceEventsList } = results[1];
          const { certificates } = results[2];
          traceEventsList.forEach((traceEvent: TraceEvent) => {
            const eventTime = dayjs(traceEvent.event_start_unix_epoch * 1000);
            const productClass = traceEvent.output_products ? traceEvent.output_products : traceEvent.input_products ? traceEvent.input_products : "";
            const item: TraceItemV2 = {
              userEvent: traceEvent.user_event,
              eventStartUnixEpoch: traceEvent.event_start_unix_epoch,
              eventTime: eventTime.format("ddd, DD MMM YYYY h:mm A"),
              location: traceEvent.location,
              productClass,
              thumbnail: traceEvent.image_thumbnail_presigned_url,
              certificates: getCertificatesForProductOrLocation(productClass, traceEvent.location, certificates)
            };
            tmpResults.push(item);
          });

          tmpResults.sort((a, b) => a.eventStartUnixEpoch - b.eventStartUnixEpoch);
          setTraces(tmpResults);

          const organizationInfo = { 
            ...results[0].org_info,
            certificates: getCertificatesForOrgInfo(certificates)
          };
          setOrgInfo(organizationInfo);
        }

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch trace data:", error);
        setError(true);
        setLoading(false);
      }
    };

    getTraces();
  }, [env, org_id, trace_item, trace_item_id, createdEpoch]);

  const renderTrace = () => {
    if (traces && orgInfo) return <TraceV2 traces={traces} orgInfo={orgInfo} />;
    return <TraceV1 traces={traces} />;
  };

  return (
    <Box sx={{ maxWidth: "100vw", minHeight: "100vh", display: "flex"}}>
      {loading ? (
        <Stack spacing={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh" }}>
          <Typography variant="h6" sx={{ color: '#000000' }}>Loading Your Product Journey...</Typography>
          <div className="loader loader-3">
            <div></div><div></div><div></div>
          </div>
        </Stack>
      ) : error ? (
        <Stack sx={{ direction: "column", alignItems: "center", justifyContent: "center", spacing: 2, width: "100vw", height: "100vh" }}>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 60 }} />
          <Typography variant="h6" sx={{ color: '#000000', textAlign: 'center' }}>
            We couldn't find your trace. Please try again.
          </Typography>
        </Stack>
      ) : (
        renderTrace()
      )}
    </Box>
  );
}
