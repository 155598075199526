import { Certificate, DocumentLevel, FlattenedCertificate } from "./types";

export const getCertificatesForProductOrLocation = (productName: string, location: string, certificates: Certificate[]) => {
    const certificatesForItem: Certificate[] = [];
    for(let i = 0; i < certificates.length; i += 1) {
        const { documentLevel, entities } = certificates[i];
        if(documentLevel === DocumentLevel.LOCATION) {
            if(entities.some(entity => entity === location)) {
                certificatesForItem.push(certificates[i]);
            }
        } else if(documentLevel === DocumentLevel.PRODUCT) {
            if(entities.some(entity => entity === productName)) {
                certificatesForItem.push(certificates[i]);
            }
        }
    }
    return certificatesForItem;
}

export const getCertificatesForOrgInfo = (certificates: Certificate[]) => {
    const certificatesForItem: Certificate[] = [];
    for(let i = 0; i < certificates.length; i += 1) {
        const { documentLevel } = certificates[i];
        if(documentLevel === DocumentLevel.ORGANIZATION) {
            certificatesForItem.push(certificates[i]);
        }
    }
    return certificatesForItem;
}

export const flattenCertificates = (
    certificates: Certificate[]
  ): FlattenedCertificate[] => {
    return certificates.flatMap(cert =>
      cert.documentPaths.map(doc => ({
        certificateName: cert.certificateName,
        documentLevel: cert.documentLevel,
        entities: cert.entities,
        createdEpoch: cert.createdEpoch,
        id: doc.id,
        url: doc.url,
        fileType: doc.fileType,
      }))
    );
  };