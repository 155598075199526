export interface Certificate {
  certificateName: string;
  documentLevel: string;
  entities: string[];
  documentPaths: DocumentPath[];
  createdEpoch: number;
}

export interface OrgInfo {
  shortName: string;
  tagLine: string;
  storyLine: string;
  companyWebsite: string;
  companyBannerImagePath: string;
  logoPath: string;
  certificates: Certificate[];
}

export interface TraceEvent {
  event_id: string;
  user_event: string;
  event_timezone_offset: string;
  event_start_unix_epoch: number;
  event_start_datetime: string;
  location: string;
  latitude: number;
  longitude: number;
  event_end_datetime: string;
  event_end_unix_epoch: number;
  input_products: string | null;
  output_products: string | null;
  image_thumbnail_presigned_url: string;
}

export interface TraceEventsListResponse {
  trace_events_list: TraceEvent[];
}

export interface OrgInfoResponse {
  org_info: OrgInfo;
}

export interface CertificatesResponse {
  certificates: Certificate[];
}

export type TraceEventsResponse = Array<OrgInfoResponse & TraceEventsListResponse & CertificatesResponse>;

export type FetchOptions = RequestInit;

export type TraceParams = {
    baseUrl: string,
    traceItem: string,
    orgId: string,
    createdEpoch?: string
}

export enum CertificateType {
  PDF = "pdf",
  IMAGE = "image",
}

export interface DocumentPath {
  id: string;
  url: string;
  fileType: CertificateType;
}

export type TracesItem = {
    userEvent: string,
    eventDay?: string,
    eventTime: string,
    location: string,
    productClass: string,
    thumbnail: string,
    eventStartUnixEpoch?: number
}

export interface TraceItemV2 extends TracesItem {
  certificates: Certificate[]
}

export enum DocumentLevel {
    PRODUCT = "product",
    ORGANIZATION = "organization",
    LOCATION =  "location"
}

export interface FlattenedCertificate {
  certificateName: string;
  documentLevel: string;
  entities: string[];
  createdEpoch: number;
  id: string;
  url: string;
  fileType: CertificateType;
}