const events_master = {
    "products": {
        "8904368514047": {
            "gs1_id": "8904368514047",
            "entity": "ALPHONSO MANGOES",
            "instance_class": "LGTIN",
        },
        "8904368514048": {
            "gs1_id": "8904368514048",
            "entity": "RATNA MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514049": {
            "gs1_id": "8904368514049",
            "entity": "MALLIKA MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514050": {
            "gs1_id": "8904368514050",
            "entity": "IMAMPASAND MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514051": {
            "gs1_id": "8904368514051",
            "entity": "SINDORA MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514052": {
            "gs1_id": "8904368514052",
            "entity": "AMRAPALI MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368516036": {
            "gs1_id": "8904368516036",
            "entity": "ALPHONSO MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516037": {
            "gs1_id": "8904368516037",
            "entity": "RATNA MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516038": {
            "gs1_id": "8904368516038",
            "entity": "MALLIKA MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516039": {
            "gs1_id": "8904368516039",
            "entity": "IMAMPASAND MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516040": {
            "gs1_id": "8904368516040",
            "entity": "SINDORA MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516041": {
            "gs1_id": "8904368516041",
            "entity": "AMRAPALI MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        }
    },
    "locations": {
        "8904368510015": {
            "name": "ARDRA ORGANICS FARM - BEDDAMPATTI",
            "gln": "8904368510015",
            "owning_gln": "8904368510003"
        },
        "8904368510016": {
            "name": "ARDRA ORGANICS BANGALORE",
            "gln": "8904368510016",
            "owning_gln": "8904368510003"
        }
    }
};

export const getProductClass = (gs1_id: string) => {
    return events_master.products[gs1_id].entity;
}

export const getLocation = (gln: string) => {
    return events_master.locations[gln].name;
}