import { useState } from "react";
import { Stack, Typography, Box, Container, Link, Skeleton, Button } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import "dayjs/locale/en-in";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Image } from "mui-image";
import CertificatePreview from "./Common/CertificatePreview";
import { Certificate, OrgInfo, TraceItemV2 } from "../types";

dayjs.extend(localizedFormat);
dayjs.locale("en-in");

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  },
  typography: {
    h6: {
      fontWeight: "bold",
    },
  },
});

type TraceV2Input = {
  traces: TraceItemV2[],
  orgInfo: OrgInfo
}

function TraceV2(props: TraceV2Input) {
  const { orgInfo, traces } = props;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [openCertificateModal, setOpenCertificateModal] = useState<boolean>(false);
  const [selectedCertificate, setSelectedCertificate] = useState<Certificate[]>([]);


  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const getFormattedUrl = (url: string) => {
    // If the URL doesn't already start with http:// or https://, prepend https://
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const handleOpenCertificate = (certificates: Certificate[]) => {
    setSelectedCertificate(certificates);
    setOpenCertificateModal(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={0} sx={{ width: "100%", maxWidth: "100%", padding: 0 }}>
        <Stack direction="column" justifyContent="center" alignItems="center"
          sx={{ height: { sm: "200px", md: "225px", lg: "250px" }, width: "100%" }}>
          {!imageLoaded && <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />}
          <Image
            src={orgInfo.companyBannerImagePath}
            alt="banner"
            style={{ objectFit: "fill", width: "100%", borderRadius: "5px", maxHeight: "100%", display: imageLoaded ? "block" : "none" }}
            onLoad={handleImageLoad}
          />
        </Stack>
        <Container maxWidth="md" id="org-info" sx={{ py: 1, px: 3, backgroundColor: "#FFFFFF", borderRadius: "8px", boxShadow: 3, mt: 2, alignItems: "center" }}>
          <Stack sx={{ direction: { xs: "column", md: "row", alignItems: "center" } }} spacing={2}>
            <Box
              component="img"
              src={orgInfo.logoPath}
              alt=""
              sx={{ width: { xs: "100%", md: "200px" }, height: "auto", alignSelf: "center" }}
            />
            <Stack direction="column" flex={1} spacing={1} sx={{ alignItems: "center"}}>
              <Typography variant="subtitle1">{orgInfo.tagLine}</Typography>
              <Typography variant="body2">{orgInfo.storyLine}</Typography>
              {
                orgInfo.certificates.length > 0 && (
                  <Button
                    onClick={() => handleOpenCertificate(orgInfo.certificates)}
                    sx={{
                      color: "#0073e6",
                      textDecoration: "none", 
                      cursor: "pointer", 
                      '&:hover': {
                        textDecoration: "underline",
                        color: "#005bb5"
                      },
                      fontWeight: 500, 
                    }}
                  >
                    View our certifications
                  </Button>
                )
              }
            </Stack>
          </Stack>
        </Container>
        <Typography variant="h5" sx={{ mt: 2 }}>Story of Your Product</Typography>
        <Timeline sx={{ px: { xs: 1, sm: 2 } }}>
          {traces && traces.map((item, idx) => (
            <TimelineItem key={idx} sx={{ pt: 0, mt: 0 }}>
              <TimelineOppositeContent sx={{ width: { xs: 80, sm: 110 }, py: 1, px: 0 }}>
                <Stack direction="column" justifyContent="right" alignItems="right">
                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>{item.userEvent}</Typography>
                  <Typography variant="caption">{item.eventTime}</Typography>
                  <Typography variant="caption" sx={{ textTransform: "capitalize" }}>{item.location}</Typography>
                </Stack>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot sx={{ p: 0, m: 1, border: "none", boxShadow: "none", backgroundColor: "white" }}>
                  <Image src="/images/Tralexho-O.png" style={{ width: "25px" }} alt="tralexho" />
                </TimelineDot>
                {(idx < traces.length - 1) && <TimelineConnector sx={{ backgroundColor: "#000000" }} />}
              </TimelineSeparator>
              <TimelineContent>
                <Stack direction="column" justifyContent="right" alignItems="right" sx={{ mb: 1 }}>
                  <Typography variant="subtitle2" sx={{ textTransform: "capitalize" }}>{item.productClass}</Typography>
                  {!imageLoaded && <Skeleton variant="rectangular" width={180} height={180} />}
                  <Image
                    src={item.thumbnail}
                    style={{ width: 180, display: imageLoaded ? "block" : "none" }}
                    alt="trace"
                    onLoad={handleImageLoad}
                  />
                  { 
                    item.certificates.length > 0 && (
                      <Button
                        onClick={() => handleOpenCertificate(item.certificates)}
                        sx={{ 
                          color: "#0073e6", 
                          textDecoration: "none", 
                          '&:hover': {
                            textDecoration: "underline",
                            color: "#005bb5"
                          },
                          // fontWeight: 500, 
                        }}
                      >
                        View our certifications
                      </Button>
                  )
                }
                </Stack>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
        <Link href={getFormattedUrl(orgInfo.companyWebsite)} target="_blank" rel="noopener noreferrer">
          <Typography variant="caption" sx={{ mt: 1, color: "#000000" }}>
            {orgInfo.companyWebsite}
          </Typography>
        </Link>
        <Typography variant="caption" sx={{ mb: 2, mt: 1 }}>
          Traceability powered by 
          <Link  href="https://tralexho.com/" target="_blank" rel="noopener noreferrer" sx={{color:"#000000", textDecoration: "none"}}>
          &nbsp;TRALEXHO
          </Link>
        </Typography>
        {openCertificateModal && 
          <CertificatePreview
            open={openCertificateModal}
            onClose={() => setOpenCertificateModal(false)}
            certificates={selectedCertificate}
          />
        }
      </Stack>
    </ThemeProvider>
  );
}

export default TraceV2;